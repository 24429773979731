div.info-box__group-scrollbar {
    position: absolute !important;
    top: 34px;
    left: 0;
    width: max-content;
    border-radius: 4px;
    box-shadow: 0 3px 13px 0 #b8c0d0;
    background-color: #ffffff;
    z-index: 15;
}

.info-box__group-list {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    li {
        display: flex;
        padding: 7px 16px 8px;
        cursor: pointer;
        & > span {
            width: 100%;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
        }
        &:hover {
            background-color: #f1f3f7;
        }
    }
}

