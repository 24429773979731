@import 'assets/styles/variables';

.box-body--graph {
	position: relative;
	h2 {
		font-size: 26px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: normal;
		margin-bottom: 50px;
		user-select: none;
	}
	.step-footer__item {
		padding: 32px 0;
	}
}

.modal-graph {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	h3 {
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;

		margin-top: 0;
	}
	.step-footer__item {
		display: flex;
		justify-content: space-between;
		padding: 9px 0 0;
	}
	.step-footer__btn-wrap {
		margin-right: 0;
	}
	&--save {
		align-items: flex-start;
        padding: 45px 30px;
		h3 {
			padding-left: 23px;
			padding-right: 23px;
			max-width: max-content;
			margin-bottom: 45px;
		}
		.step-footer__item {
			padding: 9px 0 0 122px;
		}
	}
}
.modal-graph {
    .btn {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0;
    }
    &--save {
        .btn {
            margin-top: 0;
            min-width: 152px;
        }
    }
}

.graph-date {
	max-width: max-content;
	min-width: 250px;
}

.modal-graph__prompt {
	padding-top: 16px;
	padding-left: 132px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
	background-color: transparent;
}
.react-datepicker__day.conflict-date {
	color: $color-danger !important;
}
