.box-sm {
    .step-footer {
        padding-bottom: 0;
    }
    .step-footer__item {
        padding: 23px 20px 28px;
    }
    .step-footer__item:first-child {
        border-top: 1px solid #e2e6ed;
    }
}

.choice__content--center {
    .text {
        margin: 0 0 10px;
        text-align: center;
    }

    .note {
        text-align: center;
        margin: 0 0 30px;
        font-size: 22px;
    }

    + .step-footer {
        .step-footer__item {
            justify-content: center;
            border-top: none;
        }
    }

}

.warranties {
	.table__cell--check {
		width: 4%;
		flex-shrink: 0;
	}
	.table__cell--servicename {
		width: 43%;
	}
	.table__cell--duration {
		width: 16%;
		flex-shrink: 0;
		.input {
			width: calc(100% - 10px);
		}
	}
	.table__cell--shelflife {
		width: 17%;
		flex-shrink: 0;
		.input {
			width: calc(100% - 10px);
		}
	}
	.table__cell--successchance {
		width: 20%;
		flex-shrink: 0;
		.input {
			width: 60px;
			margin-right: 13px;
		}
	}
}
