@import 'assets/styles/variables';

.container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}

.two-boxes {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
        width: 49%;

        & > .btn {
            margin-top: 28px;
        }
    }
}

.box {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #b8c0d0;
    border: 1px solid $color-secondary;

    &--with-margin {
        margin: 28px 32px 48px 33px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;

        &--border-top {
            border-top: 1px solid $color-secondary;
        }

        .search {
            margin-right: 20px;
        }

        .btn {
            flex-shrink: 0;
        }
    }

    &__header-search {
        display: flex;
        padding: 16px 20px;
    }
}

.box--min {
    width: max-content;
    margin: 0 auto;
    padding: 45px 60px 57px;
    text-align: center;

    h2 {
        margin: 0 0 14px;
        padding: 0;
        font-size: 28px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: $color-text;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: $color-text;
    }
}

.box-body {
    padding: 30px 27px;
}

.card__wrap {
    display: flex;
    align-items: flex-start;
}

.card {
    border: 1px solid $color-secondary;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px 0 #b8c0d0;
}

.card-sm {
    max-width: 460px;

    .input-group,
    .input-group__row {
        width: 82%;
    }
}

.card-header {
    border-radius: 4px 4px 0 0;
    padding: 12px 20px;
    background: #f1f3f7;
}

.card-body {
    padding: 20px;
}

.wrap-half {
    width: 50%;
}

input[type="text"].input, textarea.textarea, .input-mask {
    border-radius: 2px;
    border: solid 1px #d5dae3;
    background-color: $white;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-text;
    padding: 6px 16px 10px;
}

input[type="text"].input:focus,
input[type="password"].input:focus,
textarea.textarea:focus,
.input-mask:focus {
    outline: none;
    border-color: $color-third;
    box-shadow: 0 0 0 3px rgba($color-third, 0.6);
}

input[type="text"].input-error, .input-mask.input-error, .textarea.input-error {
    border-color: $color-danger;
    box-shadow: 0 0 0 3px rgba($color-danger, 0.6);

    &:focus {
        border-color: $color-danger;
        box-shadow: 0 0 0 3px rgba($color-danger, 0.6);
    }
}

.box-sm {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #b8c0d0;
    background-color: #ffffff;
    max-width: 562px;
    margin: 0 auto;
    padding: 45px 30px;
}

.box-xs {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #b8c0d0;
    background-color: #ffffff;
    max-width: 420px;
    margin: 0 auto;
    padding: 45px 30px 42px;

    .btn {
        width: 100%;
    }
}

.input-group {
    width: 100%;
}

.input-group__row {
    display: flex;
    margin: 10px 0 13px;

    input[type="text"] {
        border-radius: 3px 0 0 3px;
        border-right: none;
        padding-top: 14px;
        padding-bottom: 18px;
        font-size: 1rem;
        width: 100%;
    }

    .btn {
        border-radius: 0 3px 3px 0;
    }
}


.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-title-left {
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: $color-text;
    margin: 42px 30px 26px;
}

.dayCalendar_interval .patient-initial-inspection {
    position: absolute;
    top: 50%;
    left: 4px;
    color: $white;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 11px;
    cursor: pointer;
    z-index: 7;
    padding: 0;
    background-color: $color-danger;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--hover {
        display: none;
        position: absolute;
        top: -55px;
        left: -73px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        padding: 9px 17px 11px;
        background-color: rgba(#313a45, 0.95);
        border-radius: 3px;
        white-space: nowrap;

        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            border: 10px solid transparent; /* Прозрачные границы */
            border-top: 10px solid rgba(#313a45, 0.95); /* Добавляем треугольник */
            transform: translateX(-50%);
        }
    }

    &:hover span.patient-initial-inspection--hover {
        display: block;
    }

    svg {
        fill: #ffffff;
        max-height: 100%;
        max-width: 100%;
    }

    &--social {
        left: 22px;

        .patient-initial-inspection--hover {
            left: -85px;
        }
    }
    .patient-initial-inspection-svg {
        fill: #ffffff;
        max-height: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.dayCalendar_interval .patient-cell-prompt-icon-right {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 7;
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.patient-cell-prompt-icon-right--left {
        right: 25px;
    }

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        fill: #4caf50;

        &.unconfirmed_by_message {
            fill: #dc3545;
        }

        &.icon-confirmed {
            width: 13px;
            height: 13px;
        }

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            & > svg {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &:hover {
        z-index: 8;

        span.patient-cell-prompt-popup-right {
            display: block;
        }
    }
}

.dayCalendar_interval .patient-cell-prompt-popup-right {
    display: none;
    position: absolute;
    top: -55px;
    left: 50%;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    padding: 9px 17px 11px;
    background-color: rgba(#313a45, 0.95);
    border-radius: 3px;
    white-space: nowrap;
    color: #fff;
    transform: translateX(-50%);

    &::after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        border: 10px solid transparent; /* Прозрачные границы */
        border-top: 10px solid rgba(#313a45, 0.95); /* Добавляем треугольник */
        transform: translateX(-50%);
    }
}

.box__subtitle {
    display: block;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-top: 30px;
    margin-bottom: 14px;

    &--no-margin-top {
        margin-top: 0;
    }
}

.box__link {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    border-bottom: 1px dashed rgba($color-text, 0.3);
    cursor: pointer;

    &:hover {
        border-bottom-color: rgba($color-text, 0.6);
    }

    &--highlighted {
        color: #447ce6;
        border-bottom: 1px dashed rgba(68, 124, 230, 0.3);

        &:hover {
            color: #ff3e7f;
            border-bottom: 1px dashed rgba(255, 62, 127, 0.3);
        }
    }
}

.box-body--no-padding-top {
    padding-top: 0;
}

.box-body--no-padding-bottom {
    padding-bottom: 0;
}

.box-body--no-padding-vertical {
    padding-top: 0;
    padding-bottom: 0;
}

.link {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    border-bottom: 1px dashed rgba($color-text, 0.3);
    cursor: pointer;
    margin-top: 26px;
    width: max-content;
    text-decoration: none;
    color: $color-text;
    &:hover {
        border-bottom-color: rgba($color-text, 0.6);
    }
}

.text-gray {
    color: #6c7783;
}

.text-danger {
    color: $color-danger;
}

.spinner {
    & > div > div {
        background: #447CE6 !important;
        height: 3px !important;
    }
}

.help-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4f5965;
}

.icon.new-close {
    width: 14px;
    height: 14px;
}

.icon.new-close use {
    fill: #2e2f30;
}

@keyframes slideLeft {
    0% {
        transform: translateX(150%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(150%);
    }
}

.choice__date {
    position: relative;

    .new-arrow-select {
        position: absolute;
        bottom: 17px;
        right: 12px;
    }
}

.new-arrow-select {
    width: 10px;
    height: 6px;
    fill: #4f5965;
}

