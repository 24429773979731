@import 'assets/styles/variables';

.info-box {
	&__item {
		.field-date {
			input {
				max-width: 71px;
			}
			.field-date__select {
				width: 132px;
				margin: 0 8px;
			}
			.filter-select .filter-select__control {
				width: 133px;
			}
		}
		&--column {
			display: flex;
			flex-direction: column;
			.info-box__label {
				margin-bottom: 10px;
				color: $color-text;
			}
		}
	}
	&__label {
		min-width: 35%;
		font-family: $font;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #6c7783;
	}
}

.info-box__item--personalHome, .info-box__item--personalApartment, .info-box__item--personalHousing {
	input[type="text"].input, textarea.textarea, .input-mask {
		width: 15%;
	}
}
.info-box__item--personalPhone, .info-box__item--additionalPhone {
	input[type="text"].input, textarea.textarea, .input-mask {
		width: 33%;
	}
}
.info-box__item--passportSn {
	input[type="text"].input, textarea.textarea, .input-mask {
		width: 25%;
	}
}

.info-box__label .text-danger {
	padding-left: 5px;
}
