@import 'assets/styles/variables';

.graph-arrow {
	position: absolute;
	top: 75px;
	left: 0;
	display: flex;
	width: 50px;
	height: calc(100% - 130px);
	align-items: center;
	justify-content: center;
	box-shadow: -14px 2px 8px -10px rgba(79, 89, 101, 0.15);
	background-color: #ffffff;
	cursor: pointer;
	z-index: 10;
	transition: .3s;

	&:hover {
		box-shadow: -15px 2px 8px -10px rgba(79, 89, 101, 0.18);

		.new-arrow-select {
			width: 14px;
			height: 8px;
			fill: $color-text;
		}
	}

	&--left {
		box-shadow: none;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			height: 100%;
			width: 105%;
			box-shadow: -14px 2px 8px -10px rgba(79, 89, 101, 0.15);
			transform: rotate(180deg);
			transition: .3s;
		}

		&:hover {
			box-shadow: none;

			&::before {
				box-shadow: -15px 2px 8px -10px rgba(79, 89, 101, 0.18);
			}
		}
	}

	.new-arrow-select {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 6px;
		transform: translate(-50%, -50%) rotate(90deg);
		fill: #4f5965;
		transition: .3s;
	}

	&--right {
		left: auto;
		right: 0;

		.new-arrow-select {
			transform: translate(-50%, -50%) rotate(-90deg);
		}
	}
}
