@import 'assets/styles/variables';

.multiple-counter {
  position: relative;
  z-index: 2;
  &__select {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
    min-width: 88px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    border-radius: 1px;
    border: solid 1px #d5dae3;
    background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
    padding-right: 18px;
    position: relative;
    outline: none;
    cursor: pointer;
    .new-arrow {
      position: absolute;
      width: 6px;
      height: 4px;
      top: 12px;
      right: 10px;
      fill: #6c7783;
    }
  }
  &__popup {
    position: absolute;
    top: 34px;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 3px 13px 0 #b8c0d0;
    border: solid 1px #ffffff;
    background-color: #ffffff;
    padding: 21px 24px 12px;
    min-width: 238px;
    cursor: default;
    &-header {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: #2e2f30;
      margin: 0 0 19px;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .SCounterWrap {
        flex-shrink: 0;
      }
    }
    &-name {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e2f30;
      flex-shrink: 0;
      padding-right: 25px;
    }
  }
  .new-warning {
    width: 20px;
    height: 20px;
    fill: $color-danger;
    cursor: default;
  }
  &__wrap-icon {
    position: absolute;
    left: -36px;
    top: 5px;
    .prompt {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@keyframes pulse {
  from {
    //transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  50% {
    /*transform: scale3d(1.2, 1.2, 1.2);*/
    opacity: 0;
  }

  to {
    //transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.new-warning.icon.pulse {
  animation: pulse 0.5s infinite;
}
