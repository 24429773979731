@import 'assets/styles/variables';

.textarea-wrap {
  position: relative;
  margin-bottom: 23px;
  height: max-content;

  &:last-child {
    margin-bottom: 0;
  }
  div.info-box__group-scrollbar {
    top: 104px;
    width: 100%;
  }
}

.textarea-title {
  display: inline-block;
  margin-bottom: 10px;
  line-height: 1.5;
}

.textarea-variant-required {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: $color-danger;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    top: 17px;
    left: -3px;
  }
}

.textarea-variant--danger {
  .form-control,
  .form-control.active {
    border-color: $color-danger;
    box-shadow: 0 0 0 0.2rem rgba($color-danger, .5);
  }
}

.form-control {
  cursor: text;
  &--editable {
    padding: 12px 16px 16px;
    position: relative;
    min-height: 58px;
    display: flex;
    flex-wrap: wrap;
  }
  &--disabled {
    background-color: #f1f3f7;
    pointer-events: none;
    textarea {
      background-color: #f1f3f7;
    }

  }

  &.active {
    border-color: $color-third;
    box-shadow: 0 0 0 0.2rem rgba($color-third, .25);
  }
  &__textarea {
    width: 100%;
    border: none;
    background-clip: padding-box;
    resize: none;
    display: block;
    font-family: $font;
    font-weight: normal;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    &:focus {
      outline: none;
    }
  }
}

[contentEditable=true]:empty:not(:focus):before{
  content:attr(placeholder);
  color:grey;
}

.textarea-wrap {
  .info-box__group-list li > span {
    display: flex;
    flex-direction: column;
    .info-box__group-list-tree {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #4f5965;
      margin-top: 1px;
      display: flex;
      & > span {
        margin-right: 4px;
        display: flex;
        align-items: center;
        svg {
          fill:  #4f5965;
          width: 15px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }
}

.textarea-variant--parameters {
    cursor: pointer;
    .form-control,
    .form-control.active {
        border-color: #fdbc00 !important;
        box-shadow: none;
        border-radius: 3px 3px 0 0;
        margin-bottom: 0;
        cursor: pointer;
    }
    .textarea-title {
        cursor: pointer;
    }
}
.textarea-parameters {
    display: block;
    background: #fdbc00;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.14;
    letter-spacing: normal;
    padding: 3px 16px 5px;
    border-radius: 0 0 3px 3px;
    cursor: pointer;
}
