@import 'assets/styles/variables';

.table__row {
	cursor: pointer;
	position: relative;
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	min-height: 32px;
	height: max-content;
	transition: background-color 0.2s ease-in-out;

	&.over {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: calc(100% - 40px);
			height: 1px;
			background-color: $color-third;
		}

		&--top::before {
			bottom: auto;
			top: 0;
		}
		&--bottom::before {
			top: auto;
			bottom: 0;
		}
	}

	&:hover {
		background-color: #f1f2f3;
	}

	&--first {
		width: 761px;
		position: fixed;
		top: 81px;
		box-shadow: 0 9px 20px -4px rgba(0, 0, 0, 0.08);
		cursor: default;
		z-index: 12;
		background-color: #ffffff;

		&:hover {
			background-color: #ffffff;
		}
	}

	&--empty {
		height: 50px;
	}

}

.table__container {
	position: relative;

	&::before {
		position: absolute;
		content: '';
		width: 24px;
		height: 37px;
		left: -40px;
		top: -9px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		background: url('../../assets/svg/sprites/svg/arrow.svg') center no-repeat;
		background-color: #e4e5e6;
		background-size: 5px 9px;
		opacity: 0;
	}
}

.table__row:hover .table__cell:first-child .table__container::before, .table__row--active .table__cell:first-child .table__container::before {
	opacity: 1;
}

.table .table__cell {
	position: relative;
	border: none;
}

.table__age {
	font-size: 0.694rem;
	color: rgba(79, 89, 101, 0.66);
	margin-left: 23px;
}

.table .table__row--first .table__cell:first-child::before {
	content: '';
	position: absolute;
	top: -4%;
	left: -2px;
	width: 1px;
	height: calc(100vh - 73px);
	background-color: #e5e6e7;
}

.table .table__row--first .table__cell:not(:last-child)::after {
	content: '';
	position: absolute;
	top: 106%;
	right: -1px;
	width: 1px;
	height: calc(100vh - 117px);
	background-color: #e5e6e7;
}

.table__row--first .table__cell {
	background-color: transparent;
	padding: 12px 0 12px 17px;
}

.table__not-phone {
	display: block;
	background-color: rgba(79, 89, 101, 0.66);
	width: 22px;
	height: 1px;
	margin-top: 10px;
}

.table__scroll-area {
	display: flex;
	margin-left: 21px;
}

.table__clearfix {
	width: 24px;
}


.table {
	color: #2e2f30;
	position: relative;
	padding-top: 47px;
	padding-bottom: 10px;
	font-size: 1rem;
	width: 100%;

	&--without-header {
		padding-top: 11px;
	}

	.custom-scroll__thumb {
		z-index: 1;
		background-color: $white;
		right: -3px;
	}

	&--disabled {
		.table__row {
			cursor: default;

			&:hover {
				background-color: $white;
			}

			&--header {
				&:hover {
					background-color: #f1f3f7;
				}
			}
		}
	}

	&--statuses {
		.table__row {
			cursor: default;

			&:hover {
				background-color: $white;
			}

			&--header {
				&:hover {
					background-color: #f1f3f7;
				}
			}
		}
	}

	&--noscroll {

		&:after {
			content: none;
		}

		.table__row {
			&::after {
				content: none;
			}
		}
	}
}

.table__title {
	font-family: $font;
	font-weight: 600;
	font-style: normal;
	margin: 11px 0 14px 0;
}

.table__cell {
	position: relative;
	padding: 2px 5px 3px 20px;

	&--count {
		.SCounterWrap {
			margin-left: -20px;
		}
	}

	&:last-of-type {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.new-tooth,
	.new-jaw,
	.new-both_jaw,
	.new-oral_cavity,
	.new-product,
	.new-segment {
		width: 12px;
		height: 12px;
		fill: #a3aec5;
		margin-right: 10px;
	}
}

.table__del-btn {
	width: 10px;
	height: 10px;
	margin-right: 12px;
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	z-index: 3;

	.new-close {
		width: 8px;
		height: 8px;

		use {
			fill: #ff3e7f;
		}
	}

	&--disabled {
		.icon {
			opacity: 0.3;
			position: absolute;
		}
	}

	.prompt {
		bottom: 20px;
		position: absolute;
		max-width: 250px;
		right: -15px;
		line-height: 1.3;

		&__triangle {
			right: 5px;
			left: initial;
		}
	}
}

.table__row {
	&:hover {
		background-color: #f1f3f7;
	}

	&--group {
		cursor: default;
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;

		&:hover {
			background-color: transparent;
		}
		.table__cell {
			padding-top: 6px;
			padding-bottom: 6px;
		}
	}

	&--highlighted {
		font-style: italic;
		min-height: 22px;
		.table__cell {
			padding-top: 9px;
			padding-bottom: 6px;
		}
	}

	&--category {
		cursor: default;
		font-family: 'Open Sans', sans-serif;
		font-weight: 800;
		min-height: 32px;
		&:hover {
			background-color: transparent;
		}
		.table__cell {
			padding-bottom: 6px;
			padding-top: 15px;
		}
	}

	&--active {
		.table__checkbox {
			background-color: $color-accent;
			border: 1px solid $color-accent;

			.new-check {
				opacity: 1;
				fill: $white;
			}
		}
	}

	&--disabled {
		.table__checkbox {
			background-color: #f1f3f7;
		}
	}

	&--active {
		&.table__row--disabled {
			.table__checkbox {
				background-color: $color-accent;
				border: 1px solid $color-accent;
				opacity: 0.3;
			}
		}
	}

	&--chosen {
		background-color: #daebff;

		&:hover {
			background-color: #daebff !important;
		}

		.table__btn {
			&--del {
				background-color: #daebff;
				opacity: 1;
			}
		}
	}

	&--service {
		padding: 12px 0 6px 19px;
		cursor: default;

		&:hover {
			background-color: $white;
		}
	}

	&--nested {
		padding-left: 56px;

		.table__cell--sum {
			color: #9ea5b2;
		}
	}

	&--pale {
		color: #9ea5b2;

		.checkbox-txt {
			color: #9ea5b2;
		}

		.icon.new-arrow {
			fill: #9ea5b2;
		}
	}

	&--total {
		border-top: 2px solid #f1f3f7;
		cursor: default;
		justify-content: flex-end;
		margin-top: 18px;
		padding: 9px 0;
		position: relative;
		font-family: $font;
		font-weight: normal;
		font-style: normal;
		&::after {
			content: '';
			height: 117%;
			background-color: $white;
			width: 12px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
			border-top: 2px solid #f1f3f7;
		}

		&:hover {
			background-color: $white;
		}
	}

	&--header {
		width: 100%;
		position: absolute;
		top: 0;
		color: #6c7783;
		background-color: #f1f3f7;
		cursor: default;

		&:hover {
			background-color: #f1f3f7;
		}

		.new-check {
			fill: #4f5965;
			width: 12px;
			height: 10px;
		}
        .checkbox {
            .new-check {
                fill: #fff;
            }
        }

		.table__cell {
			position: relative;

			&:not(:last-of-type) {
				&::after {
					content: '';
					width: 1px;
					background-color: #9ea5b2;
					height: 61%;
					position: absolute;
					right: 0;
                    top: 50%;
                    transform: translateY(-50%);
				}
			}
		}
	}
	&--subgroup {
		padding-left: 25px;
	}
}

.table__total-column {
	display: flex;
	position: relative;
	font-family: $font;
	font-weight: normal;
	font-style: normal;
}

.table__total-labels {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 70px;
	width: 200px;
}

.table__total-label {
	padding: 4px;
	&--highlighted {
		font-family: $font;
		font-weight: 600;
		font-style: normal;
	}
}

.table__total-values {
	display: flex;
	flex-direction: column;
}

.table__total-value {
	padding: 4px;
	&--highlighted {
		font-family: $font;
		font-weight: 600;
		font-style: normal;
	}
}

.table__tooth-num {
	&:not(:last-of-type) {
		&::after {
			content: ', ';
		}
	}
}

.table__btns {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 20px;
}

.table__btn {
	cursor: pointer;
	border: none;
	outline: none !important;
	background-color: #f1f3f7;

	&--del {
		opacity: 0;
		width: 20px;
		height: 20px;
		padding: 0;
		margin-right: 8px;

		.icon-close {
			stroke: #ff3e7f;
			width: 8px;
			height: 8px;
		}

		.icon-close-min {
			width: 8px;
			height: 8px;
		}
	}
}

.table__checkbox {
	width: 18px;
	height: 18px;
	border-radius: 2px;
	border: 1px solid #d5dae3;
	background-color: $white;
	text-align: center;
	display: inline-block;
	margin-right: 7px;
	margin-top: 3px;

	.new-check {
		width: 12px;
		height: 10px;
		opacity: 0;
		display: inline-block;
		vertical-align: top;
		margin-top: 3px;
	}
}

.table__cell-select {
	margin-left: -18px;
}

input[type='text'].input.table__input {
	padding: 0 16px;
}

.__react_component_tooltip {
	&.type-dark {
		font-size: 0.875rem;
		color: white;
		background-color: #313a45;
		padding: 12px;
		border-radius: 4px;

		&.place-top {
			&:after {
				border-top-color: #313a45;
			}
		}
	}
}

.table__total-container {
	display: flex;
	flex-direction: column;
}

.row-tree {
	display: flex;
	height: max-content;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;

	.checkbox {
		pointer-events: none;
	}
}

.row-tree__arrow {
	width: 25px;
	height: 100%;
	display: block;
	position: absolute;
	left: -25px;
	top: 0;
	z-index: 2;
	flex-shrink: 0;

	svg {
		width: 8px;
		height: 10px;
		fill: #4f5965;
		transform: rotate(-90deg);
	}

	&--active svg {
		transform: rotate(0);
	}
}

.treatment-plan__service-table .table__row--nested {
	pointer-events: none;
}

.table-tabs {
	.STabs {
		padding-left: 8px;
	}
}
