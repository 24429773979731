.block-title {
  font-weight: normal;
  font-size: 1.625rem;
  margin: 0 0 28px;
}

.text-accent {
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
}

.text-note {
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
}

.total-price__text {
  margin-bottom: 20px;

  .title {
    margin-right: 20px;
  }
}

.text-center {
  text-align: center;
}

.box-title {
  line-height: 1.14;
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 28px;
  margin: 0 0 27px;
}
