@import 'assets/styles/variables';

.box-templates {
  border-top: 12px solid $color-secondary;
  padding-bottom: 72px;
  h4 {
    padding: 25px 20px 15px;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $color-text;
    margin: 0;
  }
}

.box-templates__element {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 2px 20px 6px;
  &:hover {
    background-color: #daebff;

    &::after {
      content: "+";
      color: #1fc350;
    }
  }

  span {
    width: 80%;
  }
}

.templates--sticky {
  width: 50% !important;
  max-width: 523.75px;
  z-index: 1;
  //right: 189px;
}

.templates__wrap {
  width: 618px;

  .tree__wrapper {
    height: auto;
    padding-top: 58px;
    padding-bottom: 46px;
    min-width: 100%;
  }
  .tree__item-title-group--selected {
    cursor: pointer;
  }
}

.templates--sticky-wrap {
    position: sticky;
    top: 0;
    left: 0;
}
