.total-sum {
  display: flex;
  width: 100%;
  border-top: 1px solid #e2e6ed;
  margin-top: 36px;
  justify-content: flex-end;
  &__row {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    margin-top: 11px;
  }

  &__rows {
    display: flex;
  }

  &__values {
    margin-left: 20px;
    min-width: 110px;
  }
}