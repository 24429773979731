@import 'assets/styles/variables';
.patient-card__btns {
  margin-top: 24px;

  .btn {
    margin-right: 24px;
  }
}
.popup-patient-record {
  .popup__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0 3px 23px 0 rgba(49, 58, 69, 0.5);
    background-color: #ffffff;
    padding: 45px 30px 75px;
    min-width: 542px;
  }
  .popup__title {
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: $color-text;
    margin: 0;
  }
  &__title.popup__title {
    margin-bottom: 30px;
  }
  .popup__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    .new-close {
      width: 8px;
      height: 8px;
    }
  }
  .react-datepicker-popper {
    z-index: 3;
  }
  .filter-select .filter-select__placeholder, .filter-select .filter-select__control .filter-select__single-value {
    flex-shrink: 0;
    max-width: calc(100% - 20px);
  }
  .choice__date-time {
    flex-direction: row;
    & > span {
      width: 100px;
    }
  }
  .react-datepicker-popper {
    width: 250px;
  }
  .btn {
    width: 152px;
    height: 40px;
    padding: 6px 0 10px;
    margin: 32px 0 0 125px;
  }
  .popup__text-italic {
    margin: 13px 0 0 125px;
  }
}
.popup__text-italic {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $color-text;
  display: block;
}
.popup__text-center {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $color-text;
}
.popup__desc {
  margin-bottom: 34px;
}
.choice__date-time {
  input.input {
    background: $white;
    cursor: auto;
  }
}

.popup-patient-record--new {
  .choice__date-time > span {
    width: 130px;
  }
  .popup__wrap {
    min-width: 580px;
  }
  .btn {
    margin-left: 155px;
  }
  .filter-select.colored-select .filter-select__single-value {
    min-width: 170px;
  }
}
.choice__date-time--choice {
  .filter-select {
    width: 120px;
  }
  .filter-select .filter-select__control {
    width: 120px;
    .filter-select__single-value {
      min-width: 60px;
    }
  }
}
.choice__date-time {
  input[type="text"].input--reception {
    width: 120px;
  }
  .info-box__reception-btn button {
    height: 19px;
  }
}

.card--call-history {
    .table__cell {
        display: block;
        min-width: 1px;
        min-height: 1px;
        .icon-call-history-type, .icon-call-history-status {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            & > div {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                & > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .icon-call-history-status {
            border-radius: 50%;
            fill: #FFFFFF;
            &--check {
                background: #3bcd94;
                & > div {
                    width: 12px;
                    height: 12px;
                }
            }
            &--close {
                background: #ff3e7f;
                & > div {
                    width: 8px;
                    height: 8px;
                }
            }
        }
        &--date {
            width: 167px;
        }
        &--type {
            width: 70px;
        }
        &--status {
            width: 83px;
        }
        &--user {
            width: 155px;
        }
        &--tasks {
            width: 250px;
        }
        &--comment {
            width: 193px;
        }
        &--callertype {
            width: 200px;
        }
        &--topics {
            width: 170px;
        }
    }
}
