@import 'assets/styles/variables';

.popup-photo {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#1b2530, 0.65);
	z-index: 31;
	overflow: scroll;
	.popup__close-btn {
		top: 0;
		left: -56px;
	}
	&__wrap {
		position: absolute;
		width: 1290px;
		right: 35px;
		top: 50%;
		background-color: $white;
		border-radius: 8px;
		box-shadow: 0 3px 23px 0 rgba(49, 58, 69, 0.5);
		display: flex;
		z-index: 12;
		transform: translateY(-50%);
	}
	&__left {
		width: 835px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: #353637;
		position: relative;
	}
	&__photo {
		width: 100%;
		height: calc(100% - 48px);
		display: flex;
		justify-content: center;
		align-items: center;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		img {
			max-height: 100%;
			max-width: 100%;
		}
	}
	&__bottom {
		color: $white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 30px;
		background-color: #2e2f30;
		min-height: 50px;
		&--right {
			justify-content: flex-end;
			.btn {
				padding: 0;
			}
		}
	}
	&__txt {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $white;
	}
	&__buttons {
		.btn {
			margin-right: 16px;
		}
		.btn-variant-disabled {
			padding: 5px 16px 7px;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
		}
	}
	&__right {
		width: 455px;
		display: flex;
		flex-direction: column;
		padding: 46px 30px 80px;
	}
	&__label {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 30px;
		margin-top: 0;
		& > span {
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $color-text;
			margin-bottom: 10px;
		}
		& > input, & > textarea {
			width: 100%;
		}
		& > textarea {
			min-height: 56px;
			resize: none;
		}
	}
	&__wrap--disabled {
		.popup-photo {
			&__right {
				position: relative;
				pointer-events: none;
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($white, 0.5);
				}
			}
		}
	}
	&__full-screen {
		position: absolute;
		width: 32px;
		height: 32px;
		background-color: rgba(#2e2f30, 0.9);
		border-radius: 3px;
		top: 16px;
		right: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		svg {
			width: 18px;
			height: 18px;
			fill: $white;
		}
		&:hover {
			background-color: #2e2f30;
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
	&--full {
		.popup-photo__wrap {
			top: 0;
			left: 0;
			transform: translate(0, 0);
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
		.popup-photo__left {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 13;
		}
		.popup-photo__bottom {
			display: none;
		}
		.popup-photo__photo {
			height: 100%;
		}
	}
}
.popup-photo__right {
	.choice__date-time {
		flex-direction: column;
		align-items: flex-start;
	}
}
.popup-photo__buttons {
    display: flex;
	justify-content: space-between;
	width: 102%;
}
