.filter {
	position: relative;
	z-index: 12;
    padding: 24px 0 24px 32px;
	align-items: center;
	border-bottom: 1px solid #D7D7D7;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	.switch {
		margin-left: 35px;
	}
	.filter-select {
		&--opened {
			z-index: 21;
		}
	}
}

.specializations-select {
	margin-right: 7px;
}

