
.react-tooltip {
    &--small-text {
        font-size: 11px !important;
    }
    &--dark {
        background-color: rgba(49, 58, 69, 0.95) !important;
        color: #ffffff !important;
        border: none !important;
        padding: 14px 12px 19px !important;
        &.place-top::after {
            border-top-color: rgba(49, 58, 69, 0.95) !important;
        }
        &.place-right::after {
            border-right-color: rgba(49, 58, 69, 0.95) !important;
        }
        &.place-bottom::after {
            border-bottom-color: rgba(49, 58, 69, 0.95) !important;
        }
        &.place-left::after {
            border-left-color: rgba(49, 58, 69, 0.95) !important;
        }
    }

    &--white {
        background-color: #ffffff !important;
        color: #2e2f30 !important;
        box-shadow: 0 2px 8px 0 rgba(46, 47, 48, 0.2) !important;
        border: solid 1px #e2e6ed !important;
        padding: 11px 16px 21px !important;
        &.place-top::after {
            border-top-color: #ffffff !important;
        }
        &.place-right::after {
            border-right-color: #ffffff !important;
        }
        &.place-bottom::after {
            border-bottom-color: #ffffff !important;
        }
        &.place-left::after {
            border-left-color: #ffffff !important;
        }
    }
    &--no-arrow {
        &::after {
            display: none !important;
        }
    }
}
.react-tooltip.show {
    opacity: 1 !important;
}
