@import 'assets/styles/variables';

.popup-nav {
  display: flex;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #b8c0d0;
  width: 100%;
  margin-bottom: 44px;
  padding: 0 15px;
}

.popup-nav__item {
  position: relative;
  display: flex;
  align-items: center;
  font-family: $font;
  padding: 18px 15px;
  white-space: nowrap;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: $color-text;
  .check-nav {
    width: 15px;
    height: 10px;
    fill: $color-text;
  }
  &:last-child {
    &::before, &::after {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }
}

.popup-nav__item--active {
  cursor: default;
  background: $sea;
  color: #fff;
  &::before, &::after {
    content: '';
    position: absolute;
    height: 50%;
    width: 7px;
    background-color: $sea;
  }
  &::before {
    top: 0.5px;
    right: -3px;
    transform: rotate(-12deg);
  }
  &::after {
    bottom: 1.5px;
    right: -3px;
    transform: rotate(12deg)
  }

  .check-nav use{
    stroke: #fff;
  }
}
.popup-nav__item--passed {
  color: $sea;
  cursor: pointer;
}
