.form-control {
  &:focus {
    border-color: $color-third !important;
    box-shadow: 0 0 0 0.2rem rgba($color-third, .25);
  }
}
.form-group {
  margin-bottom: 40px;
}

.wrap-half {
  .form-control {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 14px 16px 18px;
  font-family: $font;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $color-secondary;
  border-radius: 3px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  overflow: hidden;

  &:focus {
    border-color: $color-accent;
    outline: none;
  }
}

.input-title {
  margin: 0 0 10px;
}

textarea {
  &.form-control {
    resize: none;
  }
}

input[type="text"].input,
input[type="number"].input,
textarea.textarea,
input[type="password"].input {
  border-radius: 2px;
  border: solid 1px #d5dae3;
  background-color: $white;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-text;
  padding: 6px 16px 10px;
}

input[type=number].input::-webkit-inner-spin-button,
input[type=number].input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=number].input {
  -moz-appearance: textfield;
}

input[type="text"].input-password,
input[type="password"].input-password {
  padding: 6px 40px 10px;
}



.password-input__wrap {
  position: relative;
}

.input-wrap {
  display: block;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    width: 100%;
  }
}
