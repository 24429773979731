
.case-history__btns {
	.btn {
		min-width: 170px;
		height: 40px;
		margin-right: 16px;
	}
}
.form-group--active {
	padding-top: 0;
}

.box-width-50 {
	width: 50%;
}

.footer-case-history {
    padding: 0 0 30px 20px;
    .error-tips-initial-survey {
        margin-left: 15px;
    }
    & > button {
        margin-right: 20px;
    }
}
.box > .footer-case-history {
    padding-left: 26px;
}
