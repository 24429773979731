@import "assets/styles/variables";

@mixin button-states($bg) {
  background: $bg;

  &:hover {
    background: darken($bg, 8%);
    transition: background 0.3s ease;
  }

  &:active {
    background: darken($bg, 15%);
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 7px 20px 9px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: $color-text;
  background-color: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  user-select: none;
  position: relative;

  .icon-arrow {
    margin-left: 15px;
    width: 10px;
    height: 10px;
    stroke: #000;
    stroke-width: 2px;
    transform: rotate(180deg);
  }

  .check-res {
    margin-right: 15px;
    width: 10px;
    height: 10px;

    use {
      stroke: $color-secondary;
    }
  }
}

.btn-align-center {
  justify-content: center;
}

.btn-align-left {
  justify-content: flex-start;

  svg {
    margin-right: 13px;
  }
}

.btn-align-right {
  justify-content: flex-end;
}


.btn-text {
  font-size: 16px;
  line-height: 1.5;
}

.btn-print {
  padding: 6px 20px 10px;

  svg {
    margin-right: 13px;
  }
}

// COLORS

.btn-variant-default {
  border: solid 1px #d5dae3;
  border-radius: 3px;
  color: $color-text;
  background-image: linear-gradient(to bottom, #fff, #f2f4f9);
  transition: 0.3s ease;

  .new-print {
    margin-right: 13px;
    fill: $color-text;
  }

  &:hover {
    background-image: linear-gradient(to bottom, #fff, #e7ecf9);
  }

  &:active {
    border: solid 1px #d5dae3;
    box-shadow: inset 0 1px 2px 0 rgba(79, 89, 101, 0.5);
    background-color: #fbfbfd;
  }
}

.btn-variant-third {
  border: solid 1px #366ed8;
  border-radius: 3px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  background-color: #447ce6;
}

.btn-variant-success {
  border: solid 1px #31b883;
  color: #fff;
  background-color: #3bcd94;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1px 3px 0 rgba(17, 104, 70, 0.5);

  .new-print {
    fill: #fff;
  }

  .icon-arrow {
    stroke: #fff;
  }

  &:hover {
    background-color: #37df9d;
  }

  &:active {
    box-shadow: inset 0 1px 3px 0 #116846;
    background-color: #31b883;
  }

  &:focus {
    outline: none;
  }
}

.btn-variant-success--light {
  @include button-states(#f2f8de);

  color: $color-success;
}

.btn-variant-disabled {
  border: solid 1px #d5dae3;
  color: #6c7783;
  background-color: #f1f3f7;
  cursor: default;
  &:focus {
    outline: none;
  }

  .new-print {
    fill: #6c7783;
  }

  .icon-arrow {
    stroke: $color-secondary-light;
  }
}

.btn-variant-outline--secondary {
  border: 1px solid $color-secondary;
}

.btn-variant-warning {
  @include button-states(rgba($color-danger, 0.1));

  color: $color-danger;
}

.btn-variant-alert {
  border: solid 1px #da2e68;
  box-shadow: 0 1px 3px 0 rgba(182, 188, 198, 0.65);
  color: $white;
  background-image: linear-gradient(to bottom, #ff3e7f, #eb2f6e);

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(182, 188, 198, 0.65);
    background-image: linear-gradient(to bottom, #ff6498, #f24780);
  }

  &:active {
    border: solid 1px #da2e68;
    box-shadow: inset 0 1px 3px 0 #781637;
    background-color: #e3316d;
  }
}

.btn-variant-link-white {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  text-decoration: none;
  color: $white;

  span {
    border-bottom: 1px solid rgba($white, 0.3);
  }

  &:hover span {
    border-color: rgba($white, 0.6);
  }
}

.btn-variant-dark {
  border-radius: 2px;
  padding: 5px 16px 7px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: $white;
  background-color: #636666;

  &:hover {
    background-color: #858a8a;
  }
}

.btn-variant-reset {
  outline: none;
  border: 1px solid #d5dae3;
  border-radius: 3px;
  padding: 6px 24px 10px;
  background-color: $white;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(182, 188, 198, 0.65);
  }

  &:active {
    box-shadow: inset 0 1px 2px 0 rgba(79, 89, 101, 0.5);
    background-color: #fbfbfd;
  }
}
.btn-loader-disabled {
    border: solid 1px #d5dae3;
    color: #6c7783;
    background-color: #f1f3f7;
    cursor: default;
    pointer-events: none;
    box-shadow: none;
    outline: none;
    &:focus {
        outline: none;
        background: #f1f3f7;
        border-color:  #d5dae3;
        box-shadow: none;
    }
    &:hover {
        background: #f1f3f7;
        border-color:  #d5dae3;
        box-shadow: none;
    }
    &:active {
        background: #f1f3f7;
        border-color:  #d5dae3;
        box-shadow: none;
    }

    .new-print {
        fill: #6c7783;
    }

    .icon-arrow {
        stroke: $color-secondary-light;
    }
}

// SIZES

.btn-size-sm {
  border-radius: 4px;
  padding: 4px 9px 5px;
}

.btn-size-md {
  min-width: 180px;
}

.btn-size-lg {
  border-radius: 6px;
  padding: 29px 10px;
  font-size: 1rem;
}

.btn-size-long {
  border-radius: 3px;
  padding: 6px 21px 10px;
  min-width: 160px;
  font-size: 1rem;
  text-align: center;
}


// GROUPS

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-group--vertical {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;
  }

  .shld_btn_dcl {
    padding: 0 18px;
    height: 87px;
  }
}

.btn-active {
  .btn {
    border: 1px solid $color-accent;
  }
}

.new-print {
  width: 22px;
  height: 20px;
  fill: #6c7783;
}

.checkbox-none {
    opacity: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    z-index: -1;
    font-size: 14px;
}

.checkbox-tooltip {
    min-width: 80px;
    min-height: 40px;
    padding: 11px 16px 13px;
    border-radius: 4px;
    background-color: rgba(#313a45, 0.95);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3233;
    text-align: center;
    white-space: nowrap;
}


