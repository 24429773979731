@import 'assets/styles/variables';

.treatment-process__main {
  .table__row--nested {
    padding-left: 45px;

    .table__cell--name {
      padding-left: 0;
    }
  }

  .table__cell {
    &--name {
      width: 100%;
    }

    &--target {
      min-width: 83px;
    }

    &--statuses {
      min-width: 477px;
    }
    &--price {
      min-width: 127px;
      .gray-color {
        color: #9ea5b2;
      }
    }
  }
}

.treatment-process__additional {
  .table__cell {
    &--servicename {
      width: 100%;
    }

    &--target {
      min-width: 83px;
    }

    &--quantity {
      min-width: 510px;
      display: flex;
      .SCounterWrap {
        margin-right: 12px;
      }
    }

    &--totalquantity {
      min-width: 150px;
    }

    &--sum {
      min-width: 130px;
    }
  }
}

.treatment-process__materials {
  .table__cell {
    &--check {
      min-width: 66px;
    }

    &--code {
      min-width: 122px;
    }

    &--name {
      width: 100%;
    }
  }
}

.treatment-process__estimate {
  .table__cell {
    &--quantity {
      min-width: 160px;
    }

    &--name {
      width: 100%;
    }
  }
}

.treatment-process__accordion-btn {
  display: inline-block;;
  color: #447ce6;
  border-bottom: 1px dashed rgba(68, 124, 230, 0.3);
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  margin-bottom: 15px;
  transition: color 0.3s ease-in-out;

  &:hover,
  &:active {
    color: #ff3e7f;
    border-bottom: 1px dashed rgba(255, 62, 127, 0.3);;
  }
}

.treatment-process__accordion {
  overflow: hidden;
  height: auto;
}

.anim-enter {
  max-height: 0;
}

.anim-enter.anim-enter-active {
  max-height: 374px;
  transition: max-height .3s ease-in-out;
}

.anim-leave {
  max-height: 374px;
}

.anim-leave.anim-leave-active {
  max-height: 0;
  transition: max-height .3s ease-in-out;
}

.treatment-statuses {
  display: flex;
  align-items: center;
}

.treatment-status {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding: 1px 12px 3px;
  background-color: #f1f3f7;
  border-right: 1px solid #cdd3de;
  color: $color-text;
  cursor: pointer;

  &:first-child {
    border-radius: 3px 0 0 3px;
  }

  &:nth-child(4) {
    border-radius: 0 3px 3px 0;
    border-right: none;
  }

  &--blocked {
    background-color: $color-third;
    color: $white;
    border-right-color: $color-third;
    pointer-events: none;
  }

  &--changeable {
    background-color: #1fc350;
    color: $white;
    border-right-color: #1fc350;
  }

  &--empty:hover {
    background-image: linear-gradient(to bottom, #ffffff, #e7ecf9);
  }

  &--empty:active {
    box-shadow: inset 0 1px 2px 0 rgba(79, 89, 101, 0.5);
    background-color: #fbfbfd;
  }
}

.treatment-status__check {
  width: 12px;
  height: 10px;
  background-color: transparent;
  margin-left: 10px;

  &--blocked {
    fill: $color-third;
  }

  &--changeable {
    fill: #1fc350;
  }
}

.treatment-process {
  h3 {
    padding-left: 27px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #2e2f30;
    margin-top: 43px;
    margin-bottom: 0;
  }
  .table__title {
    margin-top: 0;
  }

  .doctors-tabs {
    margin-bottom: 25px;
  }
}

.box-body.box-body--treatment-process {
  padding-top: 26px;
}

.treatment-process-table-plan-editor {
    margin-bottom: 30px;
    margin-top: 24px;
}
