@import 'assets/styles/variables';

.step-footer {
  padding-bottom: 16px;
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
    border-top: 1px solid #e2e6ed;
    &:first-child {
      border-top: none;
    }
  }
  &__btn-wrap {
    display: block;
    margin-right: 16px;
  }

  .btn-print {
    min-width: 170px;
    width: 170px;
    height: 40px;
  }
}

.step-footer__btn-label {
  font-family: $font;
  font-weight: normal;
  font-style: italic;
  font-size: 0.875rem;
  color: #4f5965;
  margin: 0 0 0 16px;
}

.card {
  .step-footer {
    .step-footer__item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

