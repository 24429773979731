@import 'assets/styles/variables';

.popup-nav--patient-card {
  .popup-nav__item {
    color: $color-accent;
    cursor: pointer;
    &:hover {
      color: $color-danger;
    }
  }
  .popup-nav__item--active {
    color: $white;
    cursor: default;
    &:hover {
      color: $white;
    }
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}
