@import 'assets/styles/variables';

.treatment-process__tree {
  .table__cell {
    &--check {
      min-width: 66px;
    }

    &--code {
      min-width: 122px;
    }

    &--name {
      width: 100%;
    }
  }
}

.treatment-process__estimate {
  .table__cell {
    &--name {
      width: 100%;
    }

    &--quantity {
      min-width: 160px;
    }
  }
}
