@import 'assets/styles/variables';

.table-with-groups__wrapper {
  display: flex;
}

.tree {
  list-style: none;
  margin: 0;
  padding: 19px 0 19px 44px;
  font-size: 1rem;
  overflow: hidden;

  .tree {
    padding: 0 0 0 31px;
    overflow: visible;
    position: relative;

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: $white;
      left: -100%;
      top: 0;
      z-index: 3;
    }
  }
}

.tree__wrapper {
  position: relative;
  padding-top: 40px;
  min-width: 350px;
  width: 350px;
  max-width: max-content;
  height: 300px;

  &::after {
    content: '';
    position: absolute;
    background-color: #f1f3f7;
    width: 4px;
    height: 100%;
    top: 0;
    right: 0;
  }

  .scrollarea {
    .scrollbar-container {
      &.vertical {
        margin: 0 !important;
      }
    }
  }
}

.tree__header {
  position: absolute;
  background-color: #f1f3f7;
  width: 100%;
  top: 0;
  color: #6c7783;
  padding: 8px 0 10px 16px;
  display: flex;
  min-height: 42px;
  .SCheckboxLabel {
    margin-left: 24px;
  }
}

.tree__item {
  position: relative;
}

.tree__item-title-group {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 4px 10px 4px 0;

  &:hover {
    background-color: #f1f3f7;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: -100%;
      top: 0;
      background-color: #f1f3f7;
      z-index: 3;
    }
  }

  .new-folder {
    width: 15px;
    height: 12px;
    margin-right: 11px;
    flex-shrink: 0;
    margin-top: 5px;
  }

  &--full {
    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border: 5px solid transparent;
      border-left: 8px solid $dark-gray;
      background-color: transparent;
      top: 10px;
      left: -24px;
      z-index: 5;
    }
  }

  &--opened {
    &::before {
      border-left: 5px solid transparent;
      border-top: 8px solid $dark-gray;
    }
  }

  &--focused {
    background-color: #daebff;
    cursor: default;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: -100%;
      top: 0;
      background-color: #daebff;
      z-index: 3;
    }

    &:hover {
      background-color: #daebff;

      &::after {
        background-color: #daebff;
      }
    }
  }

  &--selected {
    background-color: #f1f8ff;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: -100%;
      top: 0;
      background-color: #f1f8ff;
      z-index: 3;
    }

    &:hover {
      background-color: #f1f8ff;

      &::after {
        background-color: #f1f8ff;
      }
    }
  }
}

.tree__item-title {
  color: $black;
  user-select: none;

  &:hover {
    text-decoration: none;
  }
}

.table-with-groups__btns {
  position: absolute;
  padding-top: 28px;

  & > button {
    margin-right: 16px;
  }
}

.table-with-groups__popup-bg {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 37, 48, 0.65);
  z-index: 11;
}

.table-with-groups__popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 45px 95px 75px 95px;
  min-width: max-content;

  .step-footer, .step-footer__item {
    padding-bottom: 0;
    padding-left: 0;
    .btn:focus {
      outline: none;
    }
  }

  &--del {
    padding: 34px 32px 52px 32px;

    .popup__btns {
      margin-left: 0;
      border-top: 1px solid #e2e6ed;
      padding-top: 27px;
    }
  }

  &--with-sidebar {
    padding: 45px 0 52px 0;

    .popup__sidebar {
      margin-left: 80px;
    }

    .popup__list {
      border-radius: 4px;
      border: solid 1px #d5dae3;
      width: 760px;

      .tree__wrapper {
        height: 224px;
        width: 760px;
      }

      .tree {
        padding-top: 15px;
        padding-right: 0;
        width: 758px;

        .tree {
          padding-top: 0;
          padding-left: 25px;
          max-width: none;
          width: 100%;
        }
      }

      .tree__wrapper {
        padding-top: 0;

        &::after {
          content: none;
        }

        .custom-scroll__track--vertical {
          .custom-scroll__thumb {
            background-color: #6c7783;
          }
        }
      }
    }

    .popup__result-list {
      height: 222px;
      position: relative;
      overflow: auto;
      &::-webkit-scrollbar { width: 3px; height: 3px;}
      &::-webkit-scrollbar-button {  background-color: #6C7783; }
      &::-webkit-scrollbar-track {  background-color: #6C7783;}
      &::-webkit-scrollbar-track-piece { background-color: #F7F7F7;}
      &::-webkit-scrollbar-thumb { height: 50px; background-color: #6C7783; border-radius: 3px;}
      &::-webkit-scrollbar-corner { background-color: #6C7783;}
      &::-webkit-resizer { background-color: #6C7783;}

      &::before {
        content: '';
        position: absolute;
        height: 12px;
        width: 100%;
        background-color: #f1f3f7;
        top: 0;
      }
    }

    .table__checkbox {
      margin-right: 15px;
      margin-top: 2px;
    }

    .tree__item-title-group {
      cursor: pointer;

      &--selected {
        background-color: $white;

        &::after {
          background-color: $white;
        }

        &:hover {
          background-color: #f1f3f7;

          &::after {
            background-color: #f1f3f7;
          }
        }

        .table__checkbox {
          background-color: $color-accent;
          border: 1px solid $color-accent;

          .new-check {
            opacity: 1;
            fill: $white;
          }
        }
      }
    }

    .table-with-groups__popup-header {
      margin-bottom: 0;
    }

    .popup__subheader {
      margin-top: 22px;
      margin-bottom: 0;
    }

    .popup__content {
      align-items: flex-start;
      margin-top: 74px;
    }

    .popup__add-service {
      padding: 0 30px;
    }

    .popup__input-wrapper {
      margin-left: 0;
      margin-bottom: 35px;
      margin-top: -32px;

      &:last-of-type {
        margin-bottom: 60px;
      }

      &--separate-label {
        margin-top: 0;
      }
    }

    .popup__btns {
      padding-top: 23px;
      padding-left: 30px;
      padding-bottom: 23px;
      width: 100%;
      margin-left: 0;
      border-top: 1px solid #e2e6ed;
    }
  }

}

.table-with-groups__popup-header {
  font-size: 1.75rem;
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  margin-bottom: 22px;
  text-align: center;
  max-width: 450px;
}

.popup__subheader {
  text-align: center;
  margin: 0 0 34px 0;

}

.popup__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__add-group {
  width: 100%;
}

.popup__input-wrapper {
  position: relative;
  display: block;
  margin-bottom: 32px;
  margin-left: 52px;
}

.popup__label {
  margin-bottom: 10px;
}

.popup__input {
  border-radius: 4px;
  border: solid 1px #d5dae3;
  font-size: 1rem;
  padding: 7px 16px 9px 16px;
  line-height: 1.5;
  width: 100%;

  &--small {
    max-width: 132px;
  }
}

.popup__btns {
  margin-left: 52px;

  button {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.popup__add-service {
  width: 100%;
  display: flex;
}

.table-with-groups__popup-text {
  max-width: 340px;
  text-align: center;
  margin: 0 0 27px 0;
}

.table-with-groups__header {
  padding: 16px 30px;
  display: flex;
  align-items: center;

  .choice__date-time {
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .search {
    margin-right: 20px;
  }
}
.popup__result-table {
  .table .table__cell.table__cell--name {
    width: 100%;
  }
}
