@import 'assets/styles/variables';

.header-popup {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 34px;
}

.header-popup__left {
  display: flex;
  flex-direction: column;
}
.header-popup__patient {
  display: flex;
  align-items: center;
  .btn {
    height: 32px;
    padding: 5px 16px 7px;
    margin-left: 24px;
    .btn-text {
      font-size: 14px;
      line-height: 1.43;
    }
  }
}
.header-popup__title {
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: $color-text;
}

.header-popup__min-txt {
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  font-weight: 600;
  letter-spacing: normal;
  color: #6c7783;
  margin-bottom: 5px;
}
.header-popup__user {
  color: $color-third;
  cursor: pointer;
  font-size: 26px;
}
.header-popup__right {
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 40px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $color-text;
}
