.prompt {
	border-radius: 4px;
	background-color: rgba(#313a45, 0.95);
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #ffffff;
	padding: 11px 16px 13px;
	width: max-content;
	max-width: 256px;
	position: relative;
	&__triangle {
		position: absolute;
		width: 16px;
		height: 8px;
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			background-color: rgba(#313a45, 0.95);
			left: 0;
			top: -12px;
			width: 16px;
			height: 16px;
			transform: rotate(45deg);
		}
	}
}
