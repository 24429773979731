@import 'assets/styles/variables';

.treatment-plan__service-table {
    .table__cell {
        &--check {
            min-width: 55px;
        }

        &--code {
            min-width: 131px;
        }

        &--price {
            min-width: 112px;
        }

        &--name {
            width: 100%;
        }
    }
}

.treatment-plan__result-table {
    .table__cell {
        &--sum {
            min-width: 220px;
        }

        &--count, &--quantity {
            min-width: 140px;
        }

        &--price {
            min-width: 180px;
        }

        &--target {
            min-width: 120px;
        }

        &--status {
            min-width: 150px;
        }

        &--name {
            width: 100%;
        }
    }
}

.treatment-plan__print-options {
    padding-left: 30px;
    padding-bottom: 30px;
    padding-top: 20px;

    .print-option {
        margin-top: 10px;
    }
}

.treatment-plan {
    .table__row {
        justify-content: space-between;
    }

    .table__cell--check {
        width: 50px;
        flex-shrink: 0;
        display: flex;
    }

    .table__cell--name {
        width: 100%;
    }

    .table__cell--target {
        width: 123px;
        flex-shrink: 0;
    }

    .table__cell--price, .table__cell--minprice, .table__cell--quantity {
        width: 130px;
        flex-shrink: 0;
    }

    .table__cell--sum {
        min-width: 160px;
        padding-right: 35px;
        flex-shrink: 0;
    }

    .btn-label-link {
        color: $color-third;
        text-decoration: underline;
        cursor: pointer;
    }

    .treatment-plan__btn-label {
        font-family: $font;
        font-weight: normal;
        font-style: italic;
        font-size: 0.875rem;
        color: #4f5965;
        margin: 0 0 0 16px;
    }

    .total-sum__values {
        min-width: 116px;
    }

    &--recommended {
        .treatment-plan__result-table {
            .table__total-labels {
                width: 80px;
            }
        }
    }
}

.card--recommended {
    .table__row--additional {
        padding-left: 0;
    }
}

.treatment-plan__top {
    margin-bottom: 23px;
}

.turnkey-price-wrap {
    display: flex;
    align-items: center;
}

.turnkey-price-info-wrap {
    position: relative;
}

.turnkey-price-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    fill: #a3aec5;
    margin-left: 5px;
    transition: all .3s;
    &:hover {
        fill: $color-third;
    }
    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
            width: 100%;
            height: 100%;
        }
    }
}

.turnkey-price {
    position: fixed;
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 rgba(46, 47, 48, 0.2);
    border: solid 1px #e2e6ed;
    padding: 7px 15px 6px 12px;
    z-index: 101;
    background: #ffffff;
    right: 0;
    top: 15px;
}

.turnkey-price-title {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #6c7783;
    margin: 0 0 1px;
    white-space: nowrap;
}

.turnkey-price-sum {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-text;
    white-space: nowrap;
    margin-bottom: 5px;
}

.turnkey-price-parts {
    display: block;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 15px;
    }
}

.turnkey-price-subtitle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $color-text;
    max-width: 328px;
    margin-bottom: 4px;
}

.turnkey-price-desc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    color: $color-text;
    display: flex;
    & > span:first-child {
        margin-right: 8px;
    }
}
