.ids {
	.table__cell--servicename {
		width: 100%;
	}
	.table__cell--target {
		width: 163px;
		flex-shrink: 0;
	}
	.table__cell--consents {
		width: 379px;
		flex-shrink: 0;
	}
	.select-checkbox {
		margin-left: -17px;
	}
}
