@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500&subset=cyrillic');

// colors
$sea: #00d6d6;
$pink-red: #ff3e7f;
$apple: #8db62a;
$shamrock: #3bcd94;
$smoke: #d5dae3;
$gunmetal: #4f5965;
$lemon: #f9d905;
$black: #2e2f30;
$white: #ffffff;
$mango: #fdbc00;
$grape: #8a00ff;
$blue: #447ce6;
$dark-gray: #6c7783;
$cloudy: #f1f3f7;

// DEFAULT THEME

$color-text: $black;
$color-accent: $sea;
$color-success: $apple;
$color-danger: $pink-red;
$color-secondary: $smoke;
$color-secondary-light: lighten($smoke, 12%);
$color-third: $blue;
$color-info: $cloudy;

// FONTS

$font-main: 'Open Sans', 'Arial', sans-serif;
$font-accent: 'Open Sans', 'Arial Black', sans-serif;
$font-italic: 'Open Sans', 'Arial', sans-serif, cursive;
$font-light: 'Open Sans', 'Arial', sans-serif;
$font: 'Open Sans', 'Arial', sans-serif;

//helpers

%abs-center {
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  margin: auto;
}
