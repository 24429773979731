@import 'assets/styles/variables';

.appt {
	background-color: #f1f3f7;
}

.appt-form {
  width: 50%;
  padding: 40px 44px 0 0;
  border-right: 1px solid $color-secondary;
}

.appt-explorer {
  padding: 44px 47px;
}

.appt-explorer__title {
  font-size: 1rem;
	font-family: $font;
	font-weight: 600;
	font-style: normal;
}

.appt-explorer__desc {
  max-width: 400px;
}

.check-group {
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon-close {
		stroke: $black;
		margin-right: 10px;
	}
}

.check-group__title-group{
	font-size: 0.824rem;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.icon-check {
		stroke: $color-success;
		margin-right: 9px;
	}
}

.check-group__btns {
	width: 250px;
	min-width: 170px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 10px;
	
	.btn {
		margin-bottom: 7px;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
	}
}
.box-sm--center {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
