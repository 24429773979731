@import 'assets/styles/variables';
.choice__date-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 23px;
    width: max-content;
    position: relative;
    user-select: none;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        width: 100px;
        word-break: break-word;
        margin-right: 25px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-text;
    }
    input {
        width: 250px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #d5dae3;
        background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        user-select: none;
        letter-spacing: normal;
        color: $color-text;
        padding: 6px 12px 10px 16px;
        cursor: pointer;
        outline: none;
        &:focus {
            box-shadow: none;
            border: solid 1px #d5dae3;
        }
    }
    select {
        width: 250px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #d5dae3;
        background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
    }
}

.kanban__header {
  width: 100%;
  box-shadow: 0 4px 3px -3px #b8c0d0;
  background-color: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.kanban__header-list {
  display: flex;
  margin: 0;
  padding: 0 35px;
  list-style: none;
  align-items: center;
}

.kanban__header-item {
  padding: 13px 10px 15px 10px;
  width: 20%;
  font-size: 0.875rem;
  line-height: 1.43;
}

.kanban__header-title {
  font-family: $font;
  font-weight: 600;
  font-style: normal;
}
.kanban__header-info {
  display: inline-block;
  margin-left: 16px;
  width: 6px;
  height: 6px;
  background-color: $color-danger;
  border-radius: 50%;
}

.kanban__body {
  display: flex;
  padding: 10px 35px 0;

  .custom-scroll__thumb {
    background-color: #6c7783;
  }

  .custom-scroll__track--vertical {
    bottom: 22px;
    top: 20px;
    right: 0 !important;
  }
  .custom-scroll__container {
    width: 20%;
  }

  .scrollarea-content {
    margin-right: 10px;
  }
}

.kanban__column {
  width: 100%;
  padding: 0 14px 0 7px;
}

.kanban-card{
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #b8c0d0;
  background-color: $white;
  font-size: 0.875rem;
  line-height: 1.33;
  overflow: hidden;
  position: relative;
  transition: background-color 5s;
  &--active {
    background-color: #fffbe8;
    transition: background-color 5s;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .kanban-card__content > .btn {
    font-size: 0.875rem;
    line-height: 1.43;
    padding: 1px 12px 3px 12px;
  }
}

.kanban-card__header {
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  font-size: 0.75rem;
  padding: 7px 16px 9px 16px;
  border-bottom: 1px solid #e2e6ed;
  color: #6c7783;
}

.kanban-card__content {
  padding: 18px 16px 28px 16px;
}

.kanban-card__info-blocks {
  margin-bottom: 16px;
}

.kanban-card__info-block {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.kanban-card__info-block-title {
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  color: #6c7783;
  margin: 0 0 6px 0;
}

.kanban-card__text {
  margin: 0;
}

.kanban-card__doc-status {
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  top: 0;
}
.choice__box--center {
    display: flex;
    justify-content: center;
    & > button {
        margin-top: 12px;
        margin-bottom: 30px;
        min-width: 180px;
    }
}
.kanban-modal {
  &--checklist {
    .popup__wrap {
      min-width: 482px;
      padding: 45px 90px 75px;
    }
  }
  .popup__title {
    margin-bottom: 17px;
  }
  &--canceled {
    .textarea-wrap {
      display: flex;
      align-items: center;
      .textarea-title {
        color: $color-text;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-right: 11px;
      }
      .form-control {
        padding: 6px 16px 10px;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
      }
    }
  }
}
.kanban-card__header--danger {
  color: $color-danger;
  display: flex;
  align-items: center;
  svg {
    width: 10px;
    height: 10px;
    fill: $color-danger;
    stroke: $color-danger;
    margin-left: 8px;
  }
}

.box--payment-schedule {
  max-width: 630px;
  h2 {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }
}
.box__payment-schedule-desc {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4f5965;
  margin-top: 13px;
}

