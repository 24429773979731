@import 'assets/styles/variables';

.checkup-content {
  display: flex;
  padding: 0 47px;
}

.icon-print {
  stroke: #000;
  stroke-width: 2px;
  padding-top: 3px;
}
.form-group__field-required {
  color: $color-danger;
}

.error-tips-initial-survey {
    font-weight: normal;
    font-style: italic;
    font-size: 0.875rem;
    color: #4f5965;
    .link {
        color: $color-third;
        border-bottom: 1px dashed rgba($color-third, 0.3);
        font-style: italic;
        font-size: 0.875rem;
        &:hover {
            border-bottom-color: rgba($color-third, 0.6);
        }
    }
}
