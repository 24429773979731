.s-slide-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: visible;
    opacity: 1;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(27, 37, 48, 0.65);
        visibility: visible;
        transition: visibility .45s ease-in-out, opacity .45s ease-in-out;
    }
    &.s-slide-modal-wrap--hidden {
        visibility: hidden;
    }
    &.s-slide-modal-wrap--minimized::after {
        visibility: hidden;
        opacity: 0;
    }

}

.s-slide-modal {
    position: absolute;
    height: 100%;
    right: 0;
    background: #f1f3f7;
    &.s-slide-modal-left {
        animation-duration: 450ms;
        animation-name: slideLeft;
    }
    &.s-slide-modal-right {
        animation-duration: 450ms;
        animation-name: slideRight;
        transform: translateX(150%);
    }
}
.s-close-button {
    position: absolute;
    z-index: 11;
    left: -48px;
    top: 16px;
    border: none;
    border-radius: 50%;
    background-color: #D7D7D7;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        display: flex;
        width: 14px;
        height: 14px;
        justify-content: center;
        align-items: center;
        fill: #2e2f30;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &:hover{
        background-color: #ffffff;
    }
}

.s-slide-modal-scroll-area {
    overflow: auto;
    height: 100%;
    padding: 30px 35px 52px;
    scroll-behavior: auto;
}
