.modal__close {
	position: absolute;
	right: 16px;
	top: 16px;
    width: 8px;
    height: 8px;
    cursor: pointer;
	& > div  {
		fill: #4f5965;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 8px;
        height: 8px;
        & > div {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
	}
}
.modal__wrap {
	padding: 45px 30px;
	min-width: 520px;
	.btn {
		margin-top: 12px;
		margin-bottom: 30px;
		margin-left: 140px;
	}
}
.modal__title {
	font-size: 28px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: center;
	color: #2e2f30;
	margin: 0 0 17px;
    &--margin-top {
        margin-top: 25px;
    }
}
.modal__text-center {
	display: block;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	margin-bottom: 34px;
}
.modal__label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 23px;
	width: max-content;
	position: relative;
	user-select: none;
}
.modal__label > span {
	width: 115px;
	word-break: break-word;
	margin-right: 25px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #2e2f30;
	.filter-select__menu {
		max-height: 100px !important;
		height: 100px;
	}
}
.modal__label input {
	width: 250px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #d5dae3;
	background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	user-select: none;
	letter-spacing: normal;
	color: #2e2f30;
	padding: 6px 12px 10px 16px;
	cursor: pointer;
	outline: none;
}
.modal__label--arrow {
	position: absolute;
	bottom: 17px;
	right: 12px;
}
.modal__label .react-datepicker-popper {
	z-index: 12;
	width: calc(100% - 140px);
}
.modal__label--min .filter-select .filter-select__control {
	width: 120px;
	.filter-select__single-value {
		min-width: 80px;
	}
}

.modal__label--min {
	input[type="text"].input--reception {
		width: 120px;
	}
	.info-box__reception-btn button {
		height: 19px;
	}
}
.modal__checkboxes {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	padding-left: 90px;
	.SCheckboxLabel {
		margin-bottom: 12px;
	}
}
.modal__buttons {
	display: flex;
	justify-content: center;
	padding-top: 30px;
	border-top: 1px solid #D7D7D7;
	margin-top: 30px;
	.btn {
		margin: 0 20px 0 0;
	}
}

.modal__wrap--comment .modal__label {
	width: 100%;
}
.modal__wrap--comment button {
    display: block;
    margin: 12px auto 30px;
}

.ReactModal__Content {
	&::-webkit-scrollbar { width: 3px; height: 3px;}
	&::-webkit-scrollbar-button {  background-color: #6C7783; }
	&::-webkit-scrollbar-track {  background-color: #6C7783;}
	&::-webkit-scrollbar-track-piece { background-color: #F7F7F7;}
	&::-webkit-scrollbar-thumb { height: 50px; background-color: #6C7783; border-radius: 3px;}
	&::-webkit-scrollbar-corner { background-color: #6C7783;}
	&::-webkit-resizer { background-color: #6C7783;}
}

.modal__wrap--add-doctor {
	width: 410px;
	min-width: 410px;
}
