.new-edit-2 {
	width: 12px;
	height: 12px;
	fill: #4f5965;
}
.new-element-group {
	width: 10px;
	height: 12px;
	margin-right: 11px;
	flex-shrink: 0;
	margin-top: 5px;
}

.icon-tooth--half,
.icon-tooth--full{
  width: 22px;
  height: 22px;
  fill: #a3aec5;
}
