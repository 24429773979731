@import 'assets/styles/variables';

.tooltip-icon {
  display: inline-block;
  border-radius: 50%;
  border: 1.5px solid rgba(#6c7783, 0.8);
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  width: 19px;
  height: 19px;
  text-align: center;
  font-size: 0.844rem;
  padding-top: 8px;
  line-height: 0;
  color: rgba(#6c7783, 0.8);
  cursor: pointer;
  position: relative;
  .prompt {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
