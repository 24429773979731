.payment-groups {
	display: flex;
	.input {
		margin-right: 10px;
		padding: 6px 16px 8px;
		line-height: normal;
		width: 250px;
	}
}
.box-body--payment-head {
	padding-bottom: 0;
}

.directions-payments-table {
	width: max-content;

	.table__cell--name {
		min-width: 161px;
	}

	.table__cell--sum {
		min-width: 135px;
	}

	.table__cell--lefttopay {
		min-width: 190px;
	}

	.table__cell--payment {
      min-width: 142px;
      .input {
        width: 100px;
        height: 25px;
        padding: 0 12px;
      }
	}
}



.payment__total-discount {
  font-size: 20px;
  margin-bottom: 5px;
}

.payment-total {
	&__row {
		font-weight: 600;
		display: flex;
		margin-bottom: 5px;

		&--amount {
			font-size: 22px;
			margin-bottom: 14px;
		}
	}

	&__label {
		width: 200px;
	}
}

.payment__payment-schedule {
	.payment-schedule {
		margin-top: 0;
		.form-control {
			max-width: 628px;
		}
	}
}

.payment-message__text {
	margin-bottom: 28px;
}

.print-invoices {
	margin: 15px 0;
}
