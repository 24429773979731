@import 'assets/styles/variables';

.invoice-patient-card {
    width: max-content;
    margin: 0 auto;
}

.invoice__header {
  font-size: 1.625rem;
  margin-top: 0;
}

.invoice-list__table {
    width: 1000px;

  .table__row {
    .table__cell {
      &--createdat {
        min-width: 180px;
      }

      &--title {
        width: 100%;
      }

      &--direction {
        min-width: 180px;
      }

      &--canceled {
        min-width: 110px;
      }

      &--autopaymentreason {
        min-width: 210px;
      }

      &--sum {
        min-width: 160px;
      }

      &--paidsum {
        min-width: 160px;
      }
    }

    &:hover {
      .table__cell {
        &--invoice {
          color: #ff3e7f;
        }
      }
    }
    &--disabled {
      cursor: default;
      &:hover {
        background-color: #fff;
        .table__tip {
          display: none;
        }
        .table__cell {
          &--invoice {
            color: #ffffff;
          }
        }
      }
    }
  }
}

