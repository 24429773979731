@import 'assets/styles/variables';

.invoice__table {
  .table__cell {
    &--service,
    &--servicename {
      width: 100%;
    }

    &--target {
        min-width: 160px;
    }

    &--price {
      min-width: 180px;
    }

    &--quantity {
      min-width: 130px;
    }

    &--sum {
      min-width: 180px;
    }

  }
}

.invoice-detail {
  .print-button {
    margin-top: 20px;
  }
}
