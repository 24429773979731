.tooltip-wrap {
  background: #fff;
}

.tooltip {
  position: relative;
  width: 250px;
  border-radius: 4px;
  box-shadow: 0 3px 13px 0 #b8c0d0;
  background-color: #ffffff;
  padding: 8px 0;

  &::before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #fff transparent;
  }
}
.tooltip-nav__group {
  margin-bottom: 7px;

  &::after {
    display: block;
    content: "";
    height: 1px;
    width: 87%;
    margin: 8px auto 0;
    background: #b8c0d0;
  }
}

.tooltip-nav__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px 10px;
  font-size: 16px;
  cursor: pointer;

  .decode {
    margin-bottom: 3px;
    margin-left: 12px;
    line-height: 1.2;
  }

  &:hover {
    background: #daebff;
  }

  .tooth-item {
    .icon-tooth {
      margin-bottom: 0;
    }
  }
}
