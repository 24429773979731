.multiple-enum-field{
  .select-checkbox {
    &__select {
      height: 40px;
    }

    &__span {
      line-height: 38px;
    }

    &__wrap {
      top: 50px;
    }

    .new-arrow {
      top: 17px;
    }
  }
}