@import 'assets/styles/variables';

.pricelist {
  padding-bottom: 69px;
}

.pricelist__header {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 26px;
}

.pricelist__service-table {
  .table__cell {
    &--code {

      min-width: 135px;
    }

    &--name {
      width: 100%;
      user-select: none;
    }

    &--price,
    &[class*="price"] {
      min-width: 160px;
    }
    &--quantity {
      min-width: 130px;
    }
  }
}

.popup__result-table {
  .table__cell {
    &--quantity {
      min-width: 160px;
    }
  }
}
