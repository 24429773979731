.rc-slider {
	position: relative;
	padding: 5px 0;
	width: 100%;
	-ms-touch-action: none;
	touch-action: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
	position: absolute;
	width: 100%;
	background-color: #e9e9e9;
	height: 4px;
	border-radius: 6px;
}
.rc-slider-track {
	position: absolute;
	left: 0;
	height: 4px;
	border-radius: 6px;
	background-color: #abe2fb;
}
.rc-slider-handle {
	position: absolute;
	margin-left: -7px;
	margin-top: -5px;
	width: 14px;
	height: 14px;
	cursor: pointer;
	cursor: -webkit-grab;
	cursor: grab;
	border-radius: 50%;
	border: solid 2px #96dbfa;
	background-color: #fff;
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}
.rc-slider-mark {
	position: absolute;
	top: 18px;
	left: 0;
	width: 100%;
	font-size: 12px;
}
.rc-slider-mark-text {
	position: absolute;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	color: #999;
}
.rc-slider-mark-text-active {
	color: #666;
}
.rc-slider-step {
	position: absolute;
	width: 100%;
	height: 4px;
	background: transparent;
}
.rc-slider-dot {
	position: absolute;
	bottom: -2px;
	margin-left: -4px;
	width: 8px;
	height: 8px;
	border: 2px solid #e9e9e9;
	background-color: #fff;
	cursor: pointer;
	border-radius: 50%;
	vertical-align: middle;
}
.rc-slider-dot-active {
	border-color: #96dbfa;
}
.rc-slider-disabled {
	background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
	background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
	border-color: #ccc;
	box-shadow: none;
	background-color: #fff;
	cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
	cursor: not-allowed !important;
}
.rc-slider-vertical {
	width: 14px;
	height: 100%;
	padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
	height: 100%;
	width: 4px;
}
.rc-slider-vertical .rc-slider-track {
	left: 5px;
	bottom: 0;
	width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
	margin-left: -5px;
	margin-bottom: -7px;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
	top: 0;
	left: 18px;
	height: 100%;
}
.rc-slider-vertical .rc-slider-step {
	height: 100%;
	width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
	left: 2px;
	margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
	margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
	margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
	animation-duration: .3s;
	animation-fill-mode: both;
	display: block !important;
	animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
	animation-duration: .3s;
	animation-fill-mode: both;
	display: block !important;
	animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
	animation-name: rcSliderTooltipZoomDownIn;
	animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
	animation-name: rcSliderTooltipZoomDownOut;
	animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
	transform: scale(0, 0);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
	0% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: scale(0, 0);
	}
	100% {
		transform-origin: 50% 100%;
		transform: scale(1, 1);
	}
}

@keyframes rcSliderTooltipZoomDownOut {
	0% {
		transform-origin: 50% 100%;
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: scale(0, 0);
	}
}
.rc-slider-tooltip {
	position: absolute;
	left: -9999px;
	top: -9999px;
	visibility: visible;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
	display: none;
}
.rc-slider-tooltip-placement-top {
	padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
	padding: 6px 2px;
	min-width: 24px;
	height: 24px;
	font-size: 12px;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-color: #6c6c6c;
	border-radius: 6px;
	box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
	bottom: 4px;
	left: 50%;
	margin-left: -4px;
	border-width: 4px 4px 0;
	border-top-color: #6c6c6c;
}

/* tooltip */

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
	display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
	animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
	animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
	animation-name: rcToolTipZoomIn;
	animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
	animation-name: rcToolTipZoomOut;
	animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
	0% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(0, 0);
	}
	100% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
	}
}
@keyframes rcToolTipZoomOut {
	0% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(0, 0);
	}
}
.rc-tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	visibility: visible;
	line-height: 1.5;
	font-size: 12px;
	background-color: rgba(0, 0, 0, 0.05);
	padding: 1px;
	opacity: 0.9;
}
.rc-tooltip-hidden {
	display: none;
}
.rc-tooltip-inner {
	padding: 8px 10px;
	color: #333333;
	text-align: left;
	text-decoration: none;
	background-color: #ffffff;
	border-radius: 3px;
	min-height: 34px;
	border: 1px solid #b1b1b1;
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
	bottom: -5px;
	margin-left: -6px;
	border-width: 6px 6px 0;
	border-top-color: #b1b1b1;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
	bottom: 1px;
	margin-left: -6px;
	border-width: 6px 6px 0;
	border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
	left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
	left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
	right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
	left: -5px;
	margin-top: -6px;
	border-width: 6px 6px 6px 0;
	border-right-color: #b1b1b1;
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
	left: 1px;
	margin-top: -6px;
	border-width: 6px 6px 6px 0;
	border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
	top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
	top: 15%;
	margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
	bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
	right: -5px;
	margin-top: -6px;
	border-width: 6px 0 6px 6px;
	border-left-color: #b1b1b1;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
	right: 1px;
	margin-top: -6px;
	border-width: 6px 0 6px 6px;
	border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
	top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
	top: 15%;
	margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
	bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
	top: -5px;
	margin-left: -6px;
	border-width: 0 6px 6px;
	border-bottom-color: #b1b1b1;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
	top: 1px;
	margin-left: -6px;
	border-width: 0 6px 6px;
	border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
	left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
	left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
	right: 15%;
}
