@import 'assets/styles/variables';

.popup-shown {
    overflow: hidden;
}

#root {
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
}

body.notifOpen .notify__content {
    display: block;
}

body.notifOpen .notify__icon {
    z-index: 1120;
}

body.notifOpen .notify__amount {
    border-color: #373c41;
}

body.notifOpen .notif_content {
    display: block;
}

body.notifOpen .notif_bell {
    z-index: 1120;
}

body.notifOpen .notif_amnt {
    border-color: #373c41;
}


body, html {
    overflow: inherit;
    color: $color-text;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
body.patient_price_list {
    height: auto;
    overflow-y: auto;
}

body.patient_price_list .App {
    overflow: hidden;
}

*, *::before, *::after {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.popup__close-btn {
    position: absolute;
    z-index: 11;
    left: -48px;
    top: 22px;
    border: none;
    border-radius: 50%;
    background-color: #D7D7D7;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__close-btn:hover {
    background-color: #ffffff;
}

.popup__scroll-area {
    overflow: auto;
    height: 100%;
    padding: 30px 35px 52px;
    scroll-behavior: auto;
}

.popup__content {
    background: #fff;
}
