@import 'assets/styles/variables';

.header-popup--patient-card {
  justify-content: flex-start;
  .btn {
    margin-left: 30px;
    width: 161px;
    height: 34px;
    padding: 5px 14px 7px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
  }
}
