.time-range_cont {
	position: relative;
	display: flex;
	align-items: center;
	height: 42px;
	margin-left: 32px;
	width: 200px;
}

.time-range_cont .time-range_label-from, .time-range_cont .time-range_label-to {
	position: absolute;
	bottom: 0;
	font-size: 13px;
	line-height: 1;
	color: #4f5965;
}

.time-range_cont .time-range_label-from {
	left: 10px;
}

.time-range_cont .time-range_label-to {
	right: 10px;
}

/* filter time range */

.filter-time-range.rc-slider {
    border: none;
    height: 1px;
    background-color: #efefef;
    padding: 0;
    border-radius: 0;
}

.filter-time-range .rc-slider-rail, .filter-time-range .rc-slider-track, .filter-time-range .rc-slider-step {
    display: none;
}

.filter-time-range .rc-slider-handle {
    top: -2px;
    width: 15px;
    height: 15px;
    margin-left: -7.5px;
    border-radius: 7px;
    border: none;
    background-color: #3de0e0;
    outline: none;
    cursor: pointer;
    transform: translateX(0) !important;
}

.filter-time-range .rc-slider-handle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    margin: -2.5px 0 0 -2.5px;
    border-radius: 2px;
    background-color: #3dcfcf;
}

