@import 'assets/styles/variables';

.box-photo {
	width: max-content;
	margin: 0 auto;
	padding: 45px 30px 64px;
	text-align: center;
	&>span {
		display: block;
		margin-top: 23px;
		margin-bottom: 17px;
	}
	&>h2 {
		margin: 0 0 31px;
		padding: 0;
		font-size: 28px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.14;
		letter-spacing: normal;
		text-align: center;
		color: $color-text;
	}
	&__dnd {
		width: 630px;
		height: 292px;
		border: dashed 1px $color-third;
		background-color: #f2f6fd;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		span {
			font-size: 18px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $color-third;
		}
	}
	.btn {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.25;
		letter-spacing: normal;
		width: 192px;
		height: 40px;

		span {
			width: 100%;
		}
	}
}
.photo-list {
	display: flex;
	flex-wrap: wrap;
}
.photo-item {
	width: 20%;
	padding-right: 15px;
	cursor: pointer;
	margin-bottom: 35px;
	&:nth-child(5n+5) {
		padding-right: 0;
	}
}
.photo-item__wrap {
	width: 100%;
	height: 154px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #e2e6ed;
	margin-bottom: 9px;
	position: relative;
	cursor: pointer;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.photo-item--add {
	.photo-item__wrap {
		border: dashed 1px $color-third;
		background-color: #f2f6fd;
	}
	.new-plus {
		width: 24px;
		height: 24px;
		fill: $color-third;
	}
}
.photo-item__input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.hidden-input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}
.box-photos {
	&>.search {
		padding: 15px 30px 16px;
		border-bottom: 1px solid #e2e6ed;
	}
}
.photo-item__text-wrap {
    width: 100%;
    display: flex;
}

.photo-item__text-teeth {
    white-space: nowrap;
    width: 50%;
    overflow: hidden;
    padding-right: 15px;
    text-overflow: ellipsis;
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2e2f30;
}

.photo-item__text-date {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #6c7783;
}
