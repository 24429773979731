// COMMON
@import "../../../assets/fonts/fonts.css";
@import '../../../assets/styles/variables';
@import '../../../assets/styles/rc-slider';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/functions';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/sprites';
@import '../../../assets/styles/vendor';
@import '../../../assets/styles/base';
// LAYOUT
@import '../../../assets/styles/forms';
@import '../../../assets/styles/tooltips';
@import '../../../assets/styles/layout';
@import '../../../assets/styles/layout-tablet';

.App {
  display: flex;
  position: relative;
  height: 100%;
  &--popup-show {
    height: 100vh;
    overflow-y: hidden;
  }
}

.indent-default {
  padding-left: 47px;
  padding-right: 47px;
 }
.border {
  border: 2px solid red;
}
