.case-history__table {
  .table__cell {
    &--check {
      min-width: 148px;
    }
  }
}
.copy-teeth {
  padding-bottom: 32px;
  border-bottom: 1px solid #e2e6ed;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
    button {
      padding: 5px 16px 9px;
      margin-left: 16px;
    }
}
.card__subtitle {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 10px;
}
.box-body--case-history {
  .wrap-half {
    width: 544px;
  }
}
.case-history__tooth {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #d5dae3;
  &--box {
    min-width: 50%;
    width: max-content;
    & > span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e2f30;
      margin: 18px 20px 0;
    }
  }
  .tooth-select {
    border-bottom: none;
    padding-top: 10px;
  }
}

.case-history-wrap-content .card__wrap {
    min-height: 250px;
}

.case-history-no-content {
    text-align: center;
    padding: 20px;
}
