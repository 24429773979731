@import 'assets/styles/variables';

.tooltip.show {
  opacity: 1;
}

.header-user {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  &:hover {
    background: #f1f3f7;
  }

  .tooltip-wrap {
    position: absolute;
    z-index: 3;
    top: 113%;
    right: 0;
  }
}

.header-user--active {
  background: #f1f3f7;
}

.header-user__data{
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 28px;
  color: #4f5965;
  margin-right: 10px;

  .arrow {
    width: 10px;
    height: 10px;
    stroke: $gunmetal;
    transform: rotate(270deg);
    transition: transform .2s;
  }
}

.icon-arrow__wrap {
  cursor: pointer;
  padding: 15px;
}

.header-user__info {
  white-space: nowrap;
  margin-right: 40px;
}

.header-user__name{
  line-height: 1.5;
  font-size: 16px;
}

.header-user__role{
  line-height: 1.71;
  font-size: 14px;
  color: $dark-gray;
}
.header-user-pic{
  position: relative;
}
.header-user-pic img{
  width: 48px;
  height: 48px;
  border-radius: 24px;
  overflow: hidden;
}
.header-user__status{
  position: absolute;
  top: 4px;
  right: -5px;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 6px;
}
.header-user__status:after{
  content: '';
  position: absolute;
  top: 3px;
  right: 3px;
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 3px;
}

.header-user-pic__wrap {
  position: relative;
}

.header-user-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  outline: 0;
  background-size: 100%;
}

.header-user__status--offline::after {
  background-color: #ff004e;
}

.header-user__status--online::after {
  background-color: #4caf50;
}
