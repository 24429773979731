
.loader {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.7);
  pointer-events: none;

  &--no-bg {
      background-color: transparent;
  }
}
