.select-checkbox {
	position: relative;
	&__select {
		width: 260px;
		height: 25px;
		border-radius: 1px;
		border: solid 1px #d5dae3;
		background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
		position: relative;
	}
	&__span {
		display: block;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		padding: 0 26px 1px 16px;
		margin-top: -2px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		&--gray {
			color: #6c7783;
		}
		&--disabled {
			padding: 1px 0 2px;
			color: #6c7783;
		}
	}
	.new-arrow {
		position: absolute;
		top: 11px;
		right: 12px;
		width: 6px;
		height: 4px;
		fill: #6c7783;
	}
	&__wrap {
		position: absolute;
		left: 0;
		top: 33px;
		min-width: 260px;
		width: max-content;
		height: max-content;
		max-width: 400px;
		max-height: 368px;
		border-radius: 4px;
		box-shadow: 0 3px 13px 0 #b8c0d0;
		background-color: #ffffff;
		padding: 8px 0;
		z-index: 13;
		overflow: hidden;
		.SCheckboxLabel {
			display: block;
			width: 100%;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			padding: 1px 26px 2px 16px;
			background-color: #ffffff;
			&:hover {
				background-color: #daebff;
			}
			&--active {
				background-color: #f1f3f7;
			}
		}
	}
	.prompt {
		position: absolute;
		top: 33px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 12;
	}
}

