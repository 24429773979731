.payment-schedule {
	margin-top: 38px;
	&__title {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #2e2f30;
		margin-bottom: 10px;
		display: block;
	}
	.form-control {
		max-width: 480px;
	}
	&__desc {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: #4f5965;
		margin-top: 9px;
		display: block;
	}
}
