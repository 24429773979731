@import 'assets/styles/variables';

.box-body--materials-list {
  padding-bottom: 100px;
}
.materials__table {
  .table__cell {
    &--code {
      min-width: 129px;
    }

    &--quantity {
      min-width: 158px;
    }

    &--measure {
      min-width: 210px;
    }

    &--name {
      width: 100%;
    }
  }
}

.materials__wrap-btn {
  margin-top: 36px;
}
.materials {
  .table__row {
    display: flex;
    justify-content: space-between;
  }
  .table__cell--number {
    width: 10%;
  }
  .table__cell--date {
    width: 20%;
  }
  .table__cell--providers {
    width: 55%;
  }
  .table__cell--sum {
    width: 15%;
  }
  .table__cell--reasons {
    width: 70%;
  }
}

.new-invoices {
  .header-popup__title {
    margin-bottom: 34px;
  }
  .table__cell--name {
    width: 100%;
  }
  .table__cell--changeprice {
    width: 146px;
    flex-shrink: 0;
    .input {
      width: 100%;
      padding: 0 16px;
    }
  }
  .table__cell--count {
    width: 188px;
    flex-shrink: 0;
  }
  .table__cell--measureshortname {
    width: 210px;
    flex-shrink: 0;
  }
  .table__cell--sum {
    width: 178px;
    flex-shrink: 0;
  }
  .table__cell--price {
    min-width: 146px;
    width: 146px;
    flex-shrink: 0;
  }
  .table__cell--quantity {
    width: 188px;
    flex-shrink: 0;
  }
  .table-with-groups {
    .table__cell--check {
      width: 66px;
      flex-shrink: 0;
    }
    .table__cell--code {
      width: 125px;
      flex-shrink: 0;
    }
    .table__cell--quantity {
      width: 202px;
      flex-shrink: 0;
    }
    .table__cell--measureshortname {
      width: 178px;
      flex-shrink: 0;
    }
  }
  .box__btns {
    margin-top: 36px;
    button:first-child {
      margin-right: 16px;
    }
  }
}

.view-write-off {
  .header-popup__title {
    margin-bottom: 34px;
  }
  .table__cell--name {
    width: 100%;
  }
  .table__cell--measureshortname {
    width: 210px;
    flex-shrink: 0;
  }
  .table__cell--quantity {
    width: 188px;
    flex-shrink: 0;
  }
  .table__cell--reason {
    width: 279px;
    flex-shrink: 0;
  }

  .box__btns {
    margin-top: 36px;
    button:first-child {
      margin-right: 16px;
    }
  }
}
