@import 'assets/styles/variables';

.graph-wrap {
    display: flex;
    background-color: #fff;
    padding: 50px 50px 3px 30px;
    border-radius: 0 4px 4px 4px;
    box-shadow: 0 1px 3px 0 #b8c0d0;
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 12px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e2e6ed;
    }
}

.graph {
    display: flex;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 67px;

    .column:first-child {
        margin-left: 32px;
    }

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        height: 4px;
        border-radius: 4px;
        background-color: rgba(#6c7783, 0.6);
        transition: background-color .4s;

        &:hover {
            background-color: #6c7783;
        }
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
    }
}

.time-interval {
    flex-shrink: 0;
    padding-left: 20px;
    padding-top: 37px;
    padding-right: 16px;
    user-select: none;
}

.time {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    margin-top: -1px;
    display: flex;
    align-items: center;
}

.column {
    flex-shrink: 0;
    margin-left: 26px;

    &__title {
        text-align: center;
        padding-bottom: 15px;
        user-select: none;
    }
}

.cell {
    border: 1px solid #e2e6ed;
    margin-top: -1px;
    position: relative;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid #e2e6ed;
    }

    &__doctor {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $color-accent;
        color: #fff;
        text-transform: uppercase;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        cursor: default;
        z-index: 33;
        position: absolute;

        &-hover {
            position: fixed;
            top: -50px;
            left: -50%;
            background-color: rgba(#313a45, 0.95);
            border-radius: 3px;
            color: #fff;
            white-space: nowrap;
            padding: 9px 16px 11px;
            z-index: 11;

            &-triangle {
                position: absolute;
                width: 16px;
                height: 8px;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: rgba(#313a45, 0.95);
                    left: 0;
                    top: -12px;
                    width: 16px;
                    height: 16px;
                    transform: rotate(45deg);
                }
            }

            &.big-tooltip {
                max-width: 420px;
                text-align: center;
                white-space: inherit;
                margin-left: -5px;
                margin-top: -22px;
            }
        }

        &-wrap {
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background-color: rgba($color-third, 0.1);
            border: 1px solid $color-third;
            z-index: 3;

            &--active {
                background-color: rgba(#e7d580, 0.1);
                border-color: #e7d580;
            }

            &--conflict {
                background-color: #fdfdfe;
                border: solid 1px #e2e6ed;
            }
        }
    }

    &__wrap, &__wrap-dashed {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 1px solid transparent;
        background-color: transparent;
        z-index: 1;

        &--active {
            background-color: rgba(#e7d580, 0.1);
            border-color: #e7d580;
        }
    }

    &__wrap-dashed--active {
        border-style: dashed;
    }

    .graph__cell-top, .graph__cell-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        height: 5px;
        background-color: transparent;
        cursor: row-resize;
        z-index: 10;
    }

    .graph__cell-top, .graph__cell-bottom {
        top: 0;
    }

}

.context-menu, div.context-menu-list, .scrollarea.context-menu-list {
    position: absolute;
    min-width: 240px;
    height: max-content;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(23, 43, 81, 0.2);
    background-color: #ffffff;
    z-index: 5;
    list-style: none;
    padding: 8px 0;
    margin: 0;
}

.scrollarea.context-menu-list {
    padding: 0;

    .scrollarea-content > ul {
        padding: 8px 0;
    }
}

.scrollarea.context-menu-list .scrollbar-container.active, .scrollarea .scrollbar-container:hover {
    opacity: 1 !important;

    .scrollbar {
        background: #6c7783 !important;
    }
}

.context-menu {
    transform: translate(50%, 50%);
}

.context-menu__item {
    position: relative;
    display: flex;
    width: 100%;
    cursor: pointer;

    & > span {
        padding: 6px 16px 10px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-text;
        width: 100%;
        white-space: nowrap;
    }

    &:hover {
        background-color: #daebff;
    }

    &--list {
        margin-bottom: 16px;

        &::before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 8px;
            width: calc(100% - 16px);
            height: 1px;
            background-color: #e2e6ed;
        }
    }

    &--disabled {
        pointer-events: none;

        & > span {
            color: #6c7783;
        }
    }
}

.context-menu-list {
    top: 10px;
    left: 98%;
    z-index: 6;
    min-width: 280px;
    padding: 0;
    display: block;

    ul {
        list-style: none;
        margin: 0;
        padding: 8px 0;
    }

    .context-menu__item span {
        padding: 6px 40px 10px;
    }

    .context-menu__item--active {
        position: relative;
        cursor: default;

        span {
            background-color: #daebff;
        }

        .new-check {
            position: absolute;
            top: 50%;
            left: 16px;
            width: 12px;
            height: 10px;
            fill: #6c7783;
            transform: translateY(-50%);
        }
    }
}

.context-menu__item--left {
    .context-menu-list {
        left: -100%;
    }
}

.prompt-date-picker {
    max-width: calc(100% - 8px);
    margin: 10px auto 20px;

    & > span {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        margin-right: 0;
        padding: 5px 12px 7px;
        border-radius: 2px;
        border: solid 1px #ede8ce;
        background-color: #fffbe8;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #4f5965;
        height: 52px;
        display: flex;
    }
}

.avatars {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    .cell__doctor {
        position: relative;
        top: auto;
        left: auto;
        margin: 4px 0;
    }
}

.cell__conflict-desc {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2e2f30;
    padding: 5px 12px;
    position: relative;

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        margin-left: 10px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #6c7783;
        cursor: default;
        position: absolute;
        bottom: 8px;
        right: 50px;
    }
}
